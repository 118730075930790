<template>
  <div>
    <saveBtn :settings="{block: true}" v-if="data.form" :onClick="function () { setSaveCalendar() }"><template v-slot:text>{{ $t('message.save', {name: $tc('message.calendar', 1)}) }}</template></saveBtn>
    <transition name="flixFadeIn">
      <div :key="show + error">
        <div v-if="show" class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
        <div v-if="error" class="flix-alert flix-alert-danger" v-html="error"></div>
      </div>
    </transition>
    <privatePDF v-if="downloadPDF" :id="data.ID "/>
  </div>
</template>
<script>
export default {
  components: {
    privatePDF () { return import('@/components/assistentPrivate/pdf') }
  },
  props: { data: Object, setNotLogin: Function },
  data () {
    return {
      show: false,
      error: false,
      variables: {},
      downloadPDF: false
    }
  },
  methods: {
    getID () {
      if (typeof this.data.ID !== 'undefined') {
        return this.data.ID
      }
      return this.$route.params.id
    },
    setSaveCalendar () {
      this.$flix_post({
        url: 'assistent/privates/save',
        data: { data: this.data },
        callback: function (ret) {
          if (ret.data[0]) {
            this.$store.commit('setPrivateCalendar', this.data.ID)
            this.setSendUserEmail()
            setTimeout(function () {
              this.downloadPDF = true
            }.bind(this), 500)
          }
        }.bind(this)
      })
    },
    setSaveCalendarNow () {
    },
    setSendUserEmail () {
      var url = this.$flix_url + this.$router.resolve({ name: 'privateEvent', params: { id: this.data.ID } }).href
      var editurl = this.$flix_url + this.$router.resolve({ name: 'privateEventEdit', params: { id: this.data.ID } }).href
      var pdf = this.$flix_url + this.$router.resolve({ name: 'privateEventPDF', params: { id: btoa(btoa(this.data.ID)) } }).href

      var txt = ''
      txt += this.$t('email.privateEvent.text')

      txt = txt.split('{name}').join('"' + this.data.title + '"')
      txt = txt.split('{password}').join(this.$createPrivatePassword(this.data.ID))
      txt = txt.split('{number}').join(this.data.ID)
      txt = txt.split('{bookingLink}').join('<a href="' + url + '" target="_blank">' + url + '</a>')
      txt = txt.split('{editLink}').join('<a href="' + editurl + '" target="_blank">' + editurl + '</a>')
      txt = txt.split('{PDFLink}').join('<a href="' + pdf + '" target="_blank">' + pdf + '</a>')

      var data = {
        data: {
          ID: this.data.ID,
          text: txt,
          title: this.$t('email.privateEvent.title').split('{name}').join(this.data.title),
          to: this.data.email
        },
        callback: function () {
          this.$router.push({ name: 'privateEvent', params: { id: this.data.ID } })
        }.bind(this)
      }
      this.$store.dispatch('setSendPrivateEmail', data)
    },
    setChangeUrl (ret) {
      if (this.$route.params.id === 'new') {
        // this.$store.getters.assistents
        this.data.ID = ret.ID
      }
    },
    updateStore (ret) {
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
  },
  computed: {

  }
}
</script>
